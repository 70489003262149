import axios from 'axios'

export default {

    async getBrands() {
        return new Promise((success, die) => {
            axios.get("/cars/brands").then(response => {
                if (response.status === 200) {
                    success(response.data)
                }
                die()
            }).catch(die)
        })
    },

    async getCarDetailsById(vehicleId) {
        return new Promise((success, die) => {
            axios.get(`/cars/${vehicleId}`).then(response => {
                if (response.status === 200) {
                    success(response.data)
                } else if (response.status === 204) {
                    success(null)
                }
                die()
            }).catch(die)
        })
    },

    async getCarDetailsByPlatenumber(platenumber, payload) {

        let url = `/cars/plate/${platenumber}`
        if (payload?.campaignTrackingId)
            url += `?campaignTrackingId=${payload.campaignTrackingId}`
        if (payload?.campaignTrackingIdType)
            url += `&campaignTrackingIdType=${payload.campaignTrackingIdType}`
        if (payload?.campaignTrackingIdExpiryIn)
            url += `&campaignTrackingIdExpiryIn=${payload.campaignTrackingIdExpiryIn}`

        try {
            let response = await axios.get(url)
            if (response.status === 200) {
                return response.data
            } else if (response.status === 204) {
                return null
            }
        } catch (e) {
            throw 500
        }
    },

    async getCarDetailsByMineType(mineType) {
        return new Promise((success, die) => {
            axios.get(`/cars/mine/${mineType}`).then(response => {
                if (response.status === 200) {
                    success(response.data)
                } else if (response.status === 204) {
                    success(null)
                }
                die()
            }).catch(die)
        })
    },

    async searchMineTypes(search, brandId, modelId) {
        return new Promise((success, die) => {

            let queryParams = brandId ? `&brandId=${brandId}` : ""
            queryParams += brandId && modelId ? `&modelId=${modelId}` : ""

            axios.get(`/cars/mine-search?term=${search}${queryParams}`).then(response => {
                if (response.status === 200) {
                    success(response.data)
                } else if (response.status === 204) {
                    success(null)
                }
                die()
            }).catch(die)
        })
    },

    async getCarModels(brandId) {
        return new Promise((success, die) => {
            axios.get(`/cars/brands/${brandId}`).then(response => {
                if (response.status === 200) {
                    success(response.data)
                } else if (response.status === 204) {
                    success(null)
                }
                die()
            }).catch(die)
        })
    },

    async getCarSubmodels(brandId, modelId) {
        return new Promise((success, die) => {
            axios.get(`/cars/brands/${brandId}/models/${modelId}`).then(response => {
                if (response.status === 200) {
                    success(response.data)
                } else if (response.status === 204) {
                    success(null)
                }
                die()
            }).catch(die)
        })
    },

    async getCarByUrl(brandId, groupName, engine) {
        let response = await axios.get(`/cars/brand/${brandId}/model/${groupName}/engine/${engine}`)
        return response.data
    }

}
