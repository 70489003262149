import {CarApi} from "../../api";
import Vue from "vue";

const state = () => ({
    user: null,
    vehicle: null,
    loading: false,
    mineTypes: [],
    error: null,
    selectionType: {
        PLATENUMBER: "Immatriculation",
        MINE_TYPE: "Type mine",
        MODEL: "Modèle",
    },
    models: {},
    brands: []
})

const getters = {
    getVehicleName: (state) => {
        let vehicle = state.vehicle
        return vehicle ? `${vehicle.brandName} ${vehicle.modelName}` : null
    }
}

const actions = {

    async checkVehicle({state, dispatch, rootGetters}, {id, type}) {

        try {
            switch (type) {
                case "MODEL": return CarApi.getCarDetailsById(id)
                case "MINE_TYPE": return dispatch('checkVehicleByMineType', id)
                case "PLATENUMBER": return CarApi.getCarDetailsByPlatenumber(id,
                    rootGetters["session/getCampaignTracking"])

                default:
                    throw `${type} car selection doesn't exists`
            }

        } finally {
            Vue.prototype.$matomo.trackEvent("Recherche véhicule", state.selectionType[type], null, type === 'PLATENUMBER' ? -0.09 : null)
        }
    },

    getVehicle({commit, state, rootState}) {

        let selectedVehicle = state.vehicle
        if (selectedVehicle) {
            return selectedVehicle
        } else {
            let user = rootState.session.user
            let preferredVehicle = user ? user.vehicles[0] : null;
            commit('setVehicle', preferredVehicle)
            return preferredVehicle
        }
    },


    searchMineTypes({state, commit}, {term, brandId, modelId}) {

        if (state.loading) {
            return
        }

        commit("setLoading", true)
        return CarApi.searchMineTypes(term, brandId, modelId).then(response => {

            if (response != null) {
                commit("setMineTypes", response)
            } else {
                commit("setError", "Nous n'avons pas trouvé type mine corresponsant à votre recherche")
            }

            return response

        }).finally(() => commit('setLoading', false))
    },

    checkVehicleByMineType({commit}, mineType) {
        commit("setMineTypes", [])
        return CarApi.getCarDetailsByMineType(mineType)
    },

    loadBrands({commit, state}) {

        if (state.brands.length) {
            return state.brands
        }

        return CarApi.getBrands().then(brands => {
            commit('setBrands', brands)
            return brands

        }).catch(() => {
            commit('setError', 'Erreur technique, veuillez réessayer')
            return []

        })
    },

    getBrandModels({commit}, brandId) {
        commit("setLoading", true)
        return CarApi.getCarModels(brandId).then(response => {

            if (response != null) {
                commit("setBrandModels", {brandId: brandId, brandModels: response})
            } else {
                commit("setError", "Nous n'avons pas trouvé de correspondance pour cette marque")
            }

            return response

        }).finally(() => commit('setLoading', false))
    },

    getCarSubmodels({commit}, {brandId, modelId}) {
        commit("setLoading", true)
        return CarApi.getCarSubmodels(brandId, modelId).then(response => {

            if (response != null) {
                commit("setBrandModels", {brandId: brandId, brandModels: response})
            } else {
                commit("setError", "Nous n'avons pas trouvé de correspondance pour cette marque")
            }

            return response

        }).finally(() => commit('setLoading', false))
    },
    async findVehicleByUrl({state, commit}, payload) {

        const computedUrl = `/${payload.brand}/${payload.group}/${payload.engine}`;
        if ( state.vehicle && state.vehicle.url === computedUrl) return state.vehicle
        try {
            let response =  await CarApi.getCarByUrl(payload.brand, payload.group, payload.engine)
            commit('setVehicle', response)
            return response
        } catch (e) {
            throw "Impossible de trouver le véhicule"
        }
    }
}

const mutations = {

    setLoading(state, status) {
        if (status) state.error = null
        state.loading = status
    },

    setError(state, error) {
        state.error = error
    },

    setVehicle(state, vehicle) {
        state.vehicle = vehicle
        // let existingVehicle = state.vehicle.filter((v) => v.platenumber === vehicle.platenumber)
        // if(existingVehicle.length === 0) {
        //     state.vehicle.push(vehicle)
        // }
    },

    setMineTypes(state, mineTypes) {
      state.mineTypes = mineTypes
    },

    resetMineTypes(state) {
        state.mineTypes = []
    },

    setBrands(state, brands) {
        state.brands = brands
    },
    setBrandModels(state, {brandId, brandModels}) {
        state.models[brandId] = brandModels
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
